export const parseStringToNumberAndBoolean = (obj) => {
    if (typeof obj === "string") {
        if (obj === "") return obj;
        else if (!isNaN(obj)) return Number(obj);
        else if (obj === "true" || obj === "false") return obj === "true";
        else return obj;
    } else if (Array.isArray(obj)) return obj.map((item) => parseStringToNumberAndBoolean(item));
    else if (typeof obj === "object") {
        const result = {};
        for (const key in obj) {
            result[key] = parseStringToNumberAndBoolean(obj[key]);
        }
        return result;
    } else return obj;
};

export const integrationsConstants = {
    GET_INTEGRATION: "GET_INTEGRATION",
    GET_INTEGRATION_SUCCESS: "GET_INTEGRATION_SUCCESS",
    GET_INTEGRATION_FAILURE: "GET_INTEGRATION_FAILURE",
    GET_INTEGRATION_UPDATE_ITEM: "GET_INTEGRATION_UPDATE_ITEM",
    CREATE_INTEGRATION: "CREATE_INTEGRATION",
    CREATED_INTEGRATIONS: "CREATED_INTEGRATIONS",
    UPDATE_INTEGRATION: "UPDATE_INTEGRATION",
    DELETE_INTEGRATION: "DELETE_INTEGRATION",
    GET_INTEGRATION_META: "GET_INTEGRATION_META",
    GET_INTEGRATION_META_SUCCESS: "GET_INTEGRATION_META_SUCCESS",
    GET_INTEGRATION_META_FAILURE: "GET_INTEGRATION_META_FAILURE",
}

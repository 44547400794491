import { React, useRef } from "V3";
import OriginalTextField from "@material-ui/core/TextField";

export const V3TextField = ({
    fullWidth = true,
    name = "unnamedTextField",
    variant = "outlined",
    value,
    defaultValue,
    onChange,
    size = "small",
    ref,
    inputRef,
    ...props
}) => {
    const internalInputRef = useRef();
    const internalFieldRef = useRef();

    /**
     * Return
     */
    return (
        <OriginalTextField
            id={name}
            name={name}
            value={value || ""}
            onChange={(e) => onChange(e.target.value, name)}
            variant={variant}
            inputRef={inputRef || internalInputRef}
            ref={ref || internalFieldRef}
            size={size}
            fullWidth={fullWidth}
            autoComplete="new"
            InputLabelProps={{
                shrink: value ? true : false,
            }}
            {...props}
        />
    );
};

import { useState } from "V3";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import MuiButton from "@material-ui/core/Button";

export const Button = ({ buttonText, children, variant = "outlined", confirmation, onClick, ...props }) => {
    const [isDialogOpen, setIsDialogOpen] = useState();

    /**
     * Approve - Cancel for Prompt
     */
    const cancelConfirmation = () => setIsDialogOpen();
    const approveConfirmation = () => {
        onClick();
        setIsDialogOpen();
    };

    /**
     * Return
     */
    return (
        <>
            <MuiButton
                variant={variant}
                size="small"
                onClick={() => {
                    if (onClick) {
                        if (confirmation) setIsDialogOpen(true);
                        else onClick();
                    }
                    setTimeout(() => {
                        document.activeElement.blur();
                    }, 0);
                }}
                {...props}
            >
                {buttonText || children}
            </MuiButton>
            {confirmation && (
                <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onBackdropClick={cancelConfirmation}
                >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{confirmation}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelConfirmation}>Cancel</Button>
                        <Button onClick={approveConfirmation} variant="contained" color="error">
                            Approve
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

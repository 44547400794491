import { useState, useEffect, Fragment, Link } from "V3";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/styles";

const linkObj = {
    color: "inherit",
    textDecoration: "none",
};
const useStyles = makeStyles({
    iconButton: {
        "&:focus": {
            outline: 0,
            border: 0,
        },
    },
    link: {
        color: "inherit",
        "&:focus": linkObj,
        "&:hover": linkObj,
        "&:active": linkObj,
    },
});

export const ActionMenu = (props) => {
    const { actions } = props || {};
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    useEffect(() => document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:scroll;"), [anchorEl]);

    return (
        <Fragment>
            <IconButton
                aria-label="add"
                aria-controls="long-menu"
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    e.stopPropagation();
                }}
                className={classes.iconButton}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    setAnchorEl(null);
                    e.stopPropagation();
                }}
                PaperProps={{
                    style: {
                        maxHeight: 500,
                        width: "25ch",
                    },
                }}
            >
                {actions.map((action, actionKey) => {
                    const MenuItemOrj = () => (
                        <MenuItem
                            key={actionKey}
                            onClick={() => {
                                if (action.linkUrl) return;
                                setAnchorEl(null);
                                action?.action && action.action();
                            }}
                        >
                            {action.label}
                        </MenuItem>
                    );

                    if (action.linkUrl)
                        return (
                            <Link
                                to={action.linkUrl}
                                className={classes.link}
                                onClick={() => {
                                    setAnchorEl(null);
                                    action?.action && action.action();
                                }}
                            >
                                <MenuItemOrj />
                            </Link>
                        );
                    else return <MenuItemOrj />;

                    // return (
                    //     <MenuItem
                    //         key={actionKey}
                    //         onClick={() => {
                    //             setAnchorEl(null);
                    //             action?.action && action.action();
                    //         }}
                    //     >
                    //         {action.label}
                    //     </MenuItem>
                    // );
                })}
            </Menu>
        </Fragment>
    );
};

import _ from "lodash";

/**
 * This function injects value and label keys in all obj keys.
 * [{id:"",name:""}] ==> [{id:"",name:"",value:"",label:""}]
 */

export const idLabelNameConvertor = (arr) => {
    let newArr = _.orderBy(arr, ["id"], ["desc"]);
    return _.forEach(newArr, (it) => Object.assign(it, { value: it.id, label: it.name }));
};

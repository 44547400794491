import { React, Icon, IconLib, isDark } from "V3";
import "./SelectBoxArrowIcon.scss";

export const SelectBoxArrowIcon = (props) => {
    /**
     * Return
     */
    return (
        <div className={"SelectBoxArrowIcon_div" + (props.onFocus ? " focus" : "")}>
            <Icon
                icon={IconLib.IconArrowDown}
                // backgroundColor="#780000"
                // backgroundHoverColor="#00f7ff"
                color={isDark ? "white" : "grey"}
                // hoverColor="red"
                // onClick={() => {}}
                width={25}
            />
        </div>
    );
};

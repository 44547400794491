import { revenuesConstants, revenuesMetaConstants } from "../constants/revenues";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case revenuesConstants.GET_REVENUES:
            //console.log(state)
            return state;
        case revenuesConstants.GET_REVENUES_SUCCESS:
            // console.log('reducer', action.data)
            return action.data;
        case revenuesConstants.GET_REVENUES_UPDATE_ITEM:
            let newState = [...state];
            newState.map(item => {
                if(item[0] === action.data[0]) {
                    item = action.data
                }
                return item;
            });
            return newState;
        case revenuesConstants.GET_REVENUES_FAILURE:
            return action.error;
        default:
            return state;
    }
}

const initialMetaState = {};
export function revenuesMetaReducer(state = initialMetaState, action) {
    switch(action.type) {
        case revenuesMetaConstants.GET_REVENUES_META:
            return state;
        case revenuesMetaConstants.GET_REVENUES_META_SUCCESS:
            return action.meta;
        case revenuesMetaConstants.GET_REVENUES_META_FAILURE:
            return action.error;
        default: return state;
    }
}

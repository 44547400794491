import { Fragment } from "V3";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    iconButton: {
        textTransform: "none",
        marginRight: "10px",
        "&:focus": {
            outline: 0,
            border: 0,
        },
    },
    linkSeperator: {
        marginLeft: "8px",
        marginRight: "13px",
        width: "1px",
        height: "20px",
        backgroundColor: "#666",
    },
});

export const CreatorsHeaderActions = (props) => {
    const { creatorsHeaderActions, layoutAssets } = props || {};
    const classes = useStyles();

    return (
        <Fragment>
            {Object.values(creatorsHeaderActions).map((action, index) => {
                if (action.hide) return null;
                const IconComponent = action.icon || GetAppIcon;
                if (action.hideLabel) {
                    return (
                        <Fragment key={index}>
                            <div className={classes.linkSeperator} />
                            <IconButton
                                title={action.label}
                                aria-label={action.label}
                                onClick={() => action.onClick(layoutAssets)}
                                fontSize="normal"
                                className={classes.iconButton}
                            >
                                <IconComponent fontSize="normal" />
                            </IconButton>
                        </Fragment>
                    );
                } else {
                    return (
                        <Fragment key={index}>
                            <div className={classes.linkSeperator} />
                            <Button
                                size="normal"
                                startIcon={<IconComponent />}
                                color="V3IconButtonColor"
                                onClick={() => action.onClick(layoutAssets)}
                                className={classes.iconButton}
                            >
                                {action.label}
                            </Button>
                        </Fragment>
                    );
                }
            })}
        </Fragment>
    );
};

//  background-color: ${(p) => (p.test2Color ? p.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};
import { styled } from "V3/Components/styledMixins/styledMixins.js";

export const Container = styled.div`
    width: 100%;
    position: relative;
    display: block;
`;

import { useSelector, LoadingAnimation, Snackbar, useEffect } from "V3";
import { openSnackBarIsolated, closeSnackBarIsolated } from "V3/Components/V3LayoutCreator/V3LayoutCreatorFunctions.js";
import { Container } from "./V3LayoutCreator2.styled.js";

export const V3LayoutCreator2 = (props) => {
    const { layout, setLayout, children } = props || {};
    const { metadata } = useSelector((state) => state);

    /**
     * SnackBar
     */
    const setSnackBarState = (p) => setLayout(p, "snack");
    const openSnackBar = (msg, type, persistent) => openSnackBarIsolated(msg, type, persistent, setSnackBarState);
    const closeSnackBar = () => closeSnackBarIsolated(layout?.snack, setSnackBarState);

    /**
     * Send layout management functions to parent
     */
    useEffect(() => {
        setLayout(metadata?.data || {}, "meta");
        setLayout(openSnackBar, "openSnackBar");
        setLayout(closeSnackBar, "closeSnackBar");
    }, [metadata]);

    /**
     * Return
     */
    if (layout?.meta?.length < 1) return null;
    return (
        <Container>
            <LoadingAnimation isLoading={layout?.isLoading === undefined ? true : layout?.isLoading} />
            <Snackbar snackBarState={layout?.snack} handleClose={closeSnackBar} />
            {children}
        </Container>
    );
};

/**
 * HELPERS
 */
const typeOfAdvanced = (data) => {
    let typeofData = typeof data;

    if (typeofData === "string") {
        return "string";
    } else if (typeofData === "number") {
        return "number";
    } else if (typeofData === "boolean") {
        return "boolean";
    } else if (typeofData === "function") {
        return "function";
    } else if (typeofData === "object") {
        if (Array.isArray(data)) {
            return "array";
        } else {
            return "object";
        }
    } else {
        return "undefined data";
    }
};
const isANumber = (data) => !isNaN(parseFloat(data)) && isFinite(data);

/**
 * RULES
 */
const hasToBeFilled = (val) => {
    const msg = "Field has to be filled.";
    let isValid = true;
    if (!val) {
        isValid = false;
    } else {
        const typeOf = typeOfAdvanced(val);
        if ((typeOf === "array" || typeOf === "string") && val.length < 1) isValid = false;
        else if (typeOf === "object" && Object.keys(val).length < 1) isValid = false;
        else if (typeOf === "number") isValid = true;
    }

    if (!isValid) return { msg: msg, status: false };
    else return { msg: "", status: true };
};
const hasToBeFilledForMultipleV3AutoComplete = (val) => {
    const msg = "Field has to be filled.";
    let isValid = true;
    const multipleVal = val?.value;

    if (!multipleVal) {
        isValid = false;
    } else {
        const typeOf = typeOfAdvanced(multipleVal);
        if ((typeOf === "array" || typeOf === "string") && multipleVal.length < 1) isValid = false;
        else if (typeOf === "object" && Object.keys(multipleVal).length < 1) isValid = false;
        else if (typeOf === "number") isValid = true;
    }

    if (!isValid) return { msg: msg, status: false };
    else return { msg: "", status: true };
};
const hasToBeANumber = (val) => {
    const msg = "Field has to be a number.";
    if (isANumber(val)) return { msg: "", status: true };
    else return { msg: msg, status: false };
};

export const rules = {
    hasToBeFilled,
    hasToBeFilledForMultipleV3AutoComplete,
    hasToBeANumber,
};

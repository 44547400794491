import { Fragment, _, Button } from "V3";
// import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    iconButton: {
        marginRight: "10px",
        "&:focus": {
            outline: 0,
            border: 0,
        },
    },
});

export const FormCreatorActions = (props) => {
    let { layoutAssets } = props || {};
    const { sectionState, isSectionChanged, isSectionValid, dynamicVariables } = layoutAssets || {};
    const { formCreatorActionsDefaultSubmit, formCreatorActions } = sectionState || {};
    const classes = useStyles();

    const onSubmit = () => {
        const formFields = sectionState.formCreatorFields;
        const formDataForSubmit = {};
        const defaultApiAdaptor = (i) => i;
        _.forOwn(formFields, (field, key) => {
            const { hide } = field || {};
            const isHidden = !hide ? false : typeof hide === "boolean" ? hide : hide(dynamicVariables);
            if (isHidden) return;
            const incomingApiAdaptor = field.apiAdaptor;
            formDataForSubmit[key] = incomingApiAdaptor
                ? incomingApiAdaptor(field.currentValue)
                : defaultApiAdaptor(field.currentValue);
        });
        layoutAssets = { ...layoutAssets, formDataForSubmit };
        formCreatorActionsDefaultSubmit.onClick(layoutAssets);
    };

    return (
        <Fragment>
            {!formCreatorActions
                ? null
                : formCreatorActions.map((button, index) => {
                      if (button.hide) return null;
                      return (
                          <Button
                              key={index}
                              variant="outlined"
                              onClick={() => button.onClick(layoutAssets)}
                              className={classes.iconButton}
                          >
                              {button.label}
                          </Button>
                      );
                  })}
            {!formCreatorActionsDefaultSubmit || formCreatorActionsDefaultSubmit.hide ? null : (
                <Button
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!isSectionChanged || !isSectionValid ? true : false}
                >
                    SUBMIT
                </Button>
            )}
        </Fragment>
    );
};

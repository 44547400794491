import { statsConstants } from "../constants/stats";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case statsConstants.GET_STATS_TOTALS_SUCCESS:
            // console.log('totals_reducer', action.data)
            return action.data;
        default:
            return state;
    }
}

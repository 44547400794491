/**
 * React Settings & Packs
 */
export const userCookieName = "dashFx_user";
export const isDark = localStorage.getItem("dashfx-darkmode") === "true" ? true : false;
export { default as AppContext } from "../store/AppContext";
export { default as React } from "react";
export {
    createContext,
    useState,
    useEffect,
    useCallback,
    useLayoutEffect,
    useContext,
    Fragment,
    useRef,
    forwardRef,
    Children,
    useMemo,
    lazy,
} from "react";
export { default as PropTypes } from "prop-types";
export { default as _ } from "lodash";
export { DateTime } from "luxon"; // https://moment.github.io/luxon/#/formatting
export { useHistory, Link } from "react-router-dom";
export { useSelector, useDispatch } from "react-redux";
export { default as moment } from "moment";
export { default as ThemeSelector } from "config/theme";
export { default as useEventListener } from "@use-it/event-listener";
export { Player as OriginalLottiePlayer } from "@lottiefiles/react-lottie-player";
export { PxaLottiePlayer } from "./Components/PxaLottiePlayer/PxaLottiePlayer.js";
export { default as styled, css } from "styled-components";
export { g } from "./Components/styledMixins/styledMixins.js";
export { useDebounce, useDebouncedCallback } from "use-debounce"; // https://bestofreactjs.com/repo/xnimorz-use-debounce-react-awesome-react-hooks
export { default as queryString } from "query-string"; // https://www.npmjs.com/package/query-string
export { default as qs } from "qs"; //https://www.npmjs.com/package/qs
export { apiCall } from "utils/ApiCalls";
export { default as axios } from "axios";

/**
 * FinV1
 */
export { OldRow } from "./Components/FinV1/OldRow/OldRow.js";
export { FinInput } from "./Components/FinV1/FinInput/FinInput.js";
export { FinSelectBox } from "./Components/FinV1/FinSelectBox/FinSelectBox.js";
export { FinSelectBoxCampaingAllocation } from "./Components/FinV1/FinSelectBoxCampaingAllocation/FinSelectBoxCampaingAllocation.js";
export { FinToggle } from "./Components/FinV1/FinToggle/FinToggle.js";
export { FinToggleSmall } from "./Components/FinV1/FinToggleSmall/FinToggleSmall.js";
export { FinSliderInput } from "./Components/FinV1/FinSliderInput/FinSliderInput.js";
export { FormFunctions } from "./Components/FinV1/FormCreator/FormFunctions.js";
export { FormCreatorErrorController } from "./Components/FinV1/FormCreator/FormCreatorErrorController.js";
export { FormValidations } from "./Components/FinV1/FormCreator/FormValidations.js";
export { InfoTextArea } from "./Components/FinV1/InfoTextArea/InfoTextArea.js";
export { ErrorTextArea } from "./Components/FinV1/ErrorTextArea/ErrorTextArea.js";
export { SelectBoxArrowIcon } from "./Components/FinV1/SelectBoxArrowIcon/SelectBoxArrowIcon.js";
export { SelectBoxOptionsList } from "./Components/FinV1/SelectBoxOptionsList/SelectBoxOptionsList.js";
export { SelectBoxMultipleSelectionsList } from "./Components/FinV1/SelectBoxMultipleSelectionsList/SelectBoxMultipleSelectionsList.js";
export { SelectBoxMultipleAllocationList } from "./Components/FinV1/SelectBoxMultipleAllocationList/SelectBoxMultipleAllocationList.js";

/**
 * Components - V3LayoutCreator
 */
export { Accordion } from "./Components/V3LayoutCreator/Accordion/Accordion.js";
export { CreatorsHeader } from "./Components/V3LayoutCreator/CreatorsHeader/CreatorsHeader.js";
export { FormCreator } from "./Components/V3LayoutCreator/FormCreator/FormCreator.js";
export { TableCreator } from "./Components/V3LayoutCreator/TableCreator/TableCreator.js";
export { V3LayoutCreator } from "./Components/V3LayoutCreator/V3LayoutCreator.js";
export { V3LayoutCreator2 } from "./Components/V3LayoutCreator2/V3LayoutCreator2.js";
export { Snackbar } from "./Components/V3LayoutCreator/Snackbar/Snackbar.js";
export { Spacer } from "./Components/V3LayoutCreator/Spacer/Spacer.js";
export { V3TextField } from "./Components/V3LayoutCreator/Inputs/V3TextField.js";
export { V3AutoComplete } from "./Components/V3LayoutCreator/Inputs/V3AutoComplete.js";
export { V3AutoCompleteWithSelectAllFeature } from "./Components/V3LayoutCreator/Inputs/V3AutoCompleteWithSelectAllFeature.js";
export { V3BooToggle } from "./Components/V3LayoutCreator/Inputs/V3BooToggle.js";
export { V3ImageSelector } from "./Components/V3LayoutCreator/Inputs/V3ImageSelector/V3ImageSelector.js";
export { CustomScrollbar } from "./Components/CustomScrollbar/CustomScrollbar.js";
export { default as RSC } from "react-scrollbars-custom"; //https://github.com/xobotyi/react-scrollbars-custom
export { useImmer } from "use-immer"; // https://immerjs.github.io/immer/example-setstate
export { current, original } from "immer"; // https://immerjs.github.io/immer/example-setstate // (p)=>{const curr = current(p);return curr;} // const curr = current(p).previewIndex;

/**
 * Components - Inputs
 */

export { TextField } from "./Components/TextField/TextField.js";
export { AutoComplete } from "./Components/AutoComplete/AutoComplete.js";
export { Select } from "./Components/Select/Select.js";
export { Button } from "./Components/Button/Button.js";
export { DatePicker } from "./Components/DatePicker/DatePicker.js";
export { DatePickerV2, updateReadyToUseDateValues } from "./Components/DatePickerV2/DatePickerV2.js";
export { useXState } from "./Components/useXState/useXState.js";
export { Flag } from "./Components/Flag/Flag.js";
export { SourceInput } from "./Components/SourceInput/SourceInput.js";
export { SwitchButton } from "../components/buttons/SwitchButton";

/**
 * Components - Others
 */
export { ActionMenu } from "./Components/ActionMenu/ActionMenu.js";
export { TableServerSide } from "./Components/Table/TableServerSide.js";
export { Table } from "./Components/Table/Table.js";
export { TextSeperator } from "./Components/TextSeperator/TextSeperator.js";
export { Icon, IconLib } from "./Components/IconLib/IconLib.js";
export { LoadingAnimation } from "./Components/LoadingAnimation/LoadingAnimation.js";
export { BugReport } from "./Components/BugReport/BugReport.js";

/**
 * Functions
 */
export { sleep } from "./Functions/sleep.js";
export { indexOf_ObjsInArr, indexOf_ObjsInArrByIndex } from "./Functions/indexOf_ObjsInArr.js";
export { ClientEvents } from "./Functions/ClientEvents.js";
export { MouseCoords } from "./Functions/MouseCoords.js";
export { ScrollPosition } from "./Functions/ScrollPosition.js";
export { ScrollWH } from "./Functions/ScrollWH.js";
export { renameObjKeysInArr } from "./Functions/renameObjKeysInArr.js";
export { findInSource } from "./Functions/findInSource.js";
export { typeOfAdvanced } from "./Functions/typeOfAdvanced.js";
export { validateStringOnlyLettersDigits } from "./Functions/validateInput.js";
export { calc } from "./Functions/calculateVals.js";
export { catchError } from "./Functions/catchError.js";
export { stringCaseConvertor } from "./Functions/stringCaseConvertor.js";
export { copyToClipboard } from "./Functions/copyToClipboard.js";
export { objectToQueryString, queryStringToObject } from "./Functions/queryStringGenerator.js";
export { parseStringToNumberAndBoolean } from "./Functions/parseStringToNumberAndBoolean.js";
export { useRefreshMetadata } from "./Functions/refreshMetadata.js";

import { metadataConstants } from "../constants/metadata";

const initialState = {};

export default function metadata(state = initialState, action) {
    switch (action.type) {
        case metadataConstants.GET_METADATA_SUCCESS:
            return { ...action.data };
        case metadataConstants.UPDATE_METADATA_PROPERTY:
            let updatedMetadata = {
                statusCode: 200,
                data: {
                    ...state.data,
                    [action.key]: action.data.data[action.key],
                }
            };
            return updatedMetadata;
        case metadataConstants.GET_METADATA_FAILURE:
            return { ...state, error: action.error };
        default:
            return state;
    }
}
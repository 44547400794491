import { React, useState, validateStringOnlyLettersDigits } from "V3";
import { CInput } from "@coreui/react";

export const SourceInput = ({ value, onChange, className, required = false }) => {
    const [isError, setIsError] = useState(false);

    function handleInputChange(event) {
        let input = event.target.value;
        if (validateStringOnlyLettersDigits(input)) {
            setIsError(false);
            onChange(input);
        } else {
            setIsError(true);
            onChange(input);
        }
    }

    return (
        <div className={className}>
            <CInput
                id="source"
                name="source"
                label="Source"
                placeholder="Source"
                style={{ borderColor: isError ? "#e55353" : null }}
                className={"source_input"}
                required={required}
                value={value}
                type="text"
                onChange={(event) => {
                    handleInputChange(event);
                }}
            />
            {isError && value.length > 0 && (
                <div style={{ color: "#e55353" }}>Please use letters and digits only. </div>
            )}
            {isError && value.length === 0 && <div style={{ color: "#e55353" }}>Source Name should not be empty. </div>}
        </div>
    );
};

import { Scrollbars } from "react-custom-scrollbars";
import { styled, g } from "V3/Components/styledMixins/styledMixins.js";

export const TrackVertical = styled.div`
    border-radius: 5rem;
    background-color: ${g.getColor("grey70")};
    background-color: red;
    cursor: pointer;
    top: 0;
    right: 0;
    height: 100%;

    &.light {
        background-color: ${g.getColor("grey20")};
        background-color: red;
    }
`;
export const ThumbVertical = styled.div`
    border-radius: 5rem;
    background-color: ${g.getColor("background")};
    background-color: green;
    cursor: pointer;
    height: 0.5rem;

    &.light {
        background-color: ${g.getColor("accent")};
        background-color: green;
    }
`;

export const CustomScrollbar = ({ children, variant = "", ...props }) => {
    return (
        <Scrollbars
            renderTrackVertical={(p) => <TrackVertical {...p} />}
            renderThumbVertical={(p) => <ThumbVertical {...p} />}
            {...props}
            // autoHide={false}
            // autoHideTimeout={5000}
            // autoHideDuration={200}
        >
            {children}
        </Scrollbars>
    );
};

export const copyToClipboard = async (text) => {
    // navigator.clipboard.writeText(text);
    // setTimeout(() => window.navigator.clipboard.writeText(text), 750);

    try {
        await navigator?.clipboard?.writeText?.(text);
    } catch (err) {
        console.error("Failed to copy text: ", err);
    }
};

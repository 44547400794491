import { rulesConditionsConstants } from "../constants/rules_conditions_constants";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case rulesConditionsConstants.GET_CONDITION:
            // console.log(state)
            return state;
        case rulesConditionsConstants.UPDATE_CONDITION:
            // console.log(action)
            return {...state, ...action.data}
        default:
            return state;
    }
}

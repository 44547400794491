import { campaignsConstants } from "../constants/campaigns";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case campaignsConstants.GET_CAMPAIGN:
            //console.log(state)
            return state;
        case campaignsConstants.GET_CAMPAIGN_SUCCESS:
            return action.data.data;
        case campaignsConstants.GET_CAMPAIGN_UPDATE_ITEM:
            let newState = [...state];
            newState.map(item => {
                if(item[0] === action.data[0]) {
                    item = action.data
                }
                return item;
            });
            return newState;
        case campaignsConstants.GET_CAMPAIGN_FAILURE:
            return action.error;
        case campaignsConstants.CREATED_CAMPAIGNS:
            return action.data;
        case campaignsConstants.GET_CAMPAIGN_ITEM:
            return action.data ;
        default:
            return state;
    }
}

import { Accordion, Fragment, isDark } from "V3";
import { Grid } from "@material-ui/core";
import { CreatorsHeaderTitleSection } from "./CreatorsHeaderTitleSection.js";
import { CreatorsHeaderActions } from "./CreatorsHeaderActions.js";

export const CreatorsHeader = (props) => {
    const { children, layoutAssets } = props || {};
    const { sectionState, sectionName, setPageState } = layoutAssets || {};
    const { creatorsHeader, creatorsHeaderActions } = sectionState || {};
    const { isExpandable } = creatorsHeader || {};
    const isExpanded = (() => {
        const exp = sectionState?.creatorsHeader?.isExpanded;
        return exp === undefined ? true : exp;
    })();

    /**
     * Open-close handler
     */
    const setIsExpanded = () => setPageState(`${sectionName}.creatorsHeader.isExpanded`, !isExpanded);

    /**
     * return
     */
    return (
        <Fragment>
            {/* HEADER SECTION */}
            <Grid
                container
                spacing={2}
                sx={{
                    paddingBottom: "15px",
                    marginBottom: "10px",
                    backgroundColor: isDark ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.2)",
                    ...sectionState?.additionalStyle,
                }}
            >
                <Grid item xs="auto">
                    {!creatorsHeader ? null : (
                        <CreatorsHeaderTitleSection
                            settings={creatorsHeader}
                            setIsExpanded={setIsExpanded}
                            isExpanded={isExpanded}
                        />
                    )}
                </Grid>
                {!creatorsHeaderActions || !isExpanded ? null : (
                    <Grid
                        container
                        item
                        xs
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ marginLeft: "10px", marginTop: "0px" }}
                    >
                        <CreatorsHeaderActions
                            creatorsHeaderActions={creatorsHeaderActions}
                            layoutAssets={layoutAssets}
                        />
                    </Grid>
                )}
            </Grid>
            {/* FIELDS - EXPANDABLE ZONE */}
            {isExpandable ? (
                <Accordion isExpanded={isExpanded} layoutAssets={layoutAssets}>
                    {children}
                </Accordion>
            ) : (
                <>{children}</>
            )}
        </Fragment>
    );
};

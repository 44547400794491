import { React, isDark, OldRow, Icon, IconLib } from "V3";
import "./FinToggleSmall.scss";

export const FinToggleSmall = (props) => {
    const { setStore, name, root, toggleText } = props;
    const localVal = root[name];

    /**
     * Return
     */
    return (
        <OldRow {...props}>
            <div
                className={"FinToggleSmall_frame" + (isDark ? "" : " light") + (!localVal ? " false" : "")}
                onClick={() => setStore(!localVal, { name: name })}
            >
                <span>{toggleText}</span>
                {!localVal ? null : (
                    <div className="FinToggleSmall_icon">
                        <Icon icon={IconLib.IconCheck} color={isDark ? "white" : "black"} width={20} />
                    </div>
                )}
            </div>
        </OldRow>
    );
};

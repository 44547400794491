import React, { useContext } from "react";
import CIcon from "@coreui/icons-react";
import AppContext from "../store/AppContext";

const EditIcon = (props) => {
    const { state } = useContext(AppContext);
    const { isBlank, noClick } = props || {};

    const editAction = () => {
        let editUrl = props.url + props.data;

        if (parseInt(state?.userSettings?.edit_tab) === 1 || isBlank) {
            window.open(editUrl, "_blank");
        } else {
            window.location.href = editUrl;
        }
    };

    return (
        <CIcon
            style={{ color: "darkgray", cursor: "pointer", marginRight: "7px" }}
            name="cil-pencil"
            height={20}
            onClick={() => {
                if (!noClick) editAction();
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit Record"
        />
    );
};

export default EditIcon;

import { useRef, useEffect, useEventListener, _, useState } from "V3";
import "./Accordion.scss";

export const Accordion = (props) => {
    const { children, isExpanded, layoutAssets } = props || {};
    const { isSectionChanged, isSectionValid } = layoutAssets || {};
    const AccordionRef = useRef();
    const [currentHeight, setCurrentHeight] = useState();

    useEffect(() => defineCurrentHeight(), [AccordionRef, isSectionChanged, isSectionValid]);
    const defineCurrentHeight = () => {
        if (!AccordionRef.current) return;
        setCurrentHeight(AccordionRef.current?.getBoundingClientRect().height);
    };
    useEventListener(
        "resize",
        _.debounce(() => defineCurrentHeight(), 500),
    );

    /**
     * Return
     */
    return (
        <div
            className="Accordion_container"
            style={{
                height: isExpanded ? (currentHeight || 100) + "px" : "0px",
                opacity: isExpanded ? 1 : 0,
            }}
        >
            <div ref={AccordionRef}>{children}</div>
        </div>
    );
};

// https://github.com/clauderic/react-sortable-hoc
import { isDark, Icon, IconLib, useState, useRef, _ } from "V3";
import {
    Container,
    UploadZone,
    OrderArea,
    ImageItem,
    Image,
    UploadZoneHiddenInput,
    Label,
    LabelInfo,
    CloseIconDiv,
} from "./V3ImageSelector.styled.js";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

export const V3ImageSelector = ({ name, value, onChange, customUpload, preventAddNew, square, ...props }) => {
    /**
     * onChange
     */
    const updateValues = (val) => onChange(val, name);

    /**
     * onSortEnd
     */
    const onSortEnd = ({ oldIndex, newIndex }) => updateValues(arrayMoveImmutable(value, oldIndex, newIndex));

    /**
     * get Images from event
     */
    const getImages = (e) => {
        const allowedFileFormats = ["image/jpeg", "image/jpg", "image/png"];
        const files = e.target.files || e.dataTransfer.files || [];
        const newImageItems = [];
        let currentPosition = value.length;
        if (files.length < 0) return;
        _.forEach(files, (item) => {
            if (!allowedFileFormats.includes(item.type)) return;
            currentPosition = currentPosition + 1;
            newImageItems.push({
                position: currentPosition,
                file: item,
                value: item.name,
                path: URL.createObjectURL(item),
            });
        });
        updateValues([...value, ...newImageItems]);
    };

    /**
     * delete or mark as deleted an image
     */
    const deleteAnImage = (item, index) => {
        const duplicatedItems = _.cloneDeep(value);
        // if (item.isManual) {
        duplicatedItems.splice(index, 1);
        // } else {
        //     duplicatedItems[index].deleted = true;
        // }
        updateValues(duplicatedItems);
    };

    /**
     * Return
     */
    return (
        <Container>
            {customUpload === "true" && (
                <>
                    <Label>Offer Thumbs</Label>
                    <LabelInfo>The single image size has to be lower than 200 kb.</LabelInfo>
                </>
            )}
            <SortableList
                items={value}
                onSortEnd={onSortEnd}
                axis="x"
                lockAxis="x"
                square={square}
                {...{ getImages, deleteAnImage }}
                preventAddNew={preventAddNew}
            />
        </Container>
    );
};

const UploadFile = (props) => {
    const { getImages } = props || {};
    const draggableZone = useRef();
    const input = useRef();
    const [isHover, setHover] = useState();

    /**
     * file Input event and drop event
     */
    const fileInputEvent = (e) => getImages(e);
    const dropEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getImages(e);
    };

    /**
     * Allow Drop
     */
    const allowDrop = (e) => e.preventDefault();

    /**
     * Return
     */
    return (
        <>
            <UploadZoneHiddenInput type="file" multiple="multiple" ref={input} onChange={fileInputEvent} />
            <UploadZone
                ref={draggableZone}
                htmlFor={input}
                onDragEnter={() => setHover(true)}
                onDragLeave={() => setHover(true)}
                onMouseOut={() => setHover()}
                onDrop={dropEvent}
                onDragOver={(e) => allowDrop(e)}
                onClick={() => input.current.click()}
                {...{ isHover, isDark }}
            >
                <Icon icon={IconLib.IconPlus} color={isDark ? "white" : "black"} width={75} />
                <div style={{ textAlign: "center" }}>Drag your images here OR click to upload.</div>
            </UploadZone>
        </>
    );
};

const SortableList = SortableContainer(({ items, getImages, deleteAnImage, preventAddNew, square }) => {
    return (
        <OrderArea>
            {!items
                ? null
                : items.map((item, index) => {
                      return (
                          <SortableItem
                              key={`item-${item}`}
                              indexNo={index}
                              {...{ deleteAnImage, item, index, square }}
                          />
                      );
                  })}
            {!preventAddNew && <UploadFile {...{ getImages }} />}
        </OrderArea>
    );
});

const SortableItem = SortableElement(({ item, deleteAnImage, indexNo, square }) => {
    const deleted = item?.deleted;
    const imRef = useRef();

    /**
     * Im Error Handler
     */
    const imageErrorHandler = () => {
        imRef.current.src = require("V3/Pages/CampaignBoxes/Offers/EditOffer/fxSquare.svg").default;
        imRef.current.onError = null;
    };
    /**
     * Return
     */
    return (
        <ImageItem {...{ isDark, square, deleted }}>
            <CloseIconDiv onClick={() => deleteAnImage(item, indexNo)}>
                <Icon icon={IconLib.IconClose} color={isDark ? "white" : "black"} width={20} />
            </CloseIconDiv>
            <Image
                ref={imRef}
                onError={imageErrorHandler}
                src={item.small || item.path}
                alt="thumb"
                style={square && { width: "300px", height: "300px" }}
            />
            {/* <Image src={item.small || item.path} alt="thumb" /> */}
        </ImageItem>
    );
});

import { _, useMemo } from "V3";
import { useSelector } from "react-redux";

export const Flag = (props) => {
    const { code, label } = props || {};
    const { metadata } = useSelector((state) => state);

    const getCode = (code, label) => {
        // Imps.
        if (code === "0" || code === "Global" || label === "Global") return "_global";
        if (!code && !label) return "_blank";

        const geoList = metadata?.data?.geos;

        if (code) {
            const currCode = code.toLowerCase();
            const index = _.findIndex(geoList, (o) => o.code.toLowerCase() === currCode);
            if (index > -1) return currCode;
            else return "_blank";
        }
        if (label) {
            const index = _.findIndex(geoList, (o) => o.country === label);
            if (index > -1) {
                return geoList[index].code.toLowerCase();
            } else return "_blank";
        }
    };
    const svg = useMemo(() => getCode(code, label), [code, label]);

    /**
     * Return
     */
    if (!svg) return null;
    return <img src={require(`./svgs/${svg}.svg`).default} alt={label} style={{ width: "100%" }} />;
};

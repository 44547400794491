import { Container } from "./TextSeperator.styled.js";

export const TextSeperator = (props) => {
    const { t = "", style, onClick, block, marginTop } = props || {};

    /**
     * Return
     */
    return <Container {...{ style, onClick, block, marginTop }}>{t}</Container>;
};

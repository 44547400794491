export const revenuesConstants = {
    GET_REVENUES: "GET_REVENUES",
    
    GET_REVENUES_SUCCESS: "GET_REVENUES_SUCCESS",
    GET_REVENUES_FAILURE: "GET_REVENUES_FAILURE",
    GET_REVENUES_UPDATE_ITEM: "GET_REVENUES_UPDATE_ITEM"
}

export const revenuesMetaConstants = { 
    GET_REVENUES_META: "GET_REVENUES_META",
    GET_REVENUES_META_SUCCESS: "GET_REVENUES_META_SUCCESS",
    GET_REVENUES_META_FAILURE: "GET_REVENUES_META_FAILURE",
}


const initialState = [];

export default function userProfile(state = initialState, action) {
    switch (action.type) {
        case 'SET_USER_PROFILE':
            return action.data;
        default:
            return state;
    }
}

export const campaignsConstants = {
    GET_CAMPAIGN: "GET_CAMPAIGN",
    GET_CAMPAIGN_SUCCESS: "GET_CAMPAIGN_SUCCESS",
    GET_CAMPAIGN_FAILURE: "GET_CAMPAIGN_FAILURE",
    GET_CAMPAIGN_UPDATE_ITEM: "GET_CAMPAIGN_UPDATE_ITEM",
    CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
    CREATED_CAMPAIGNS: "CREATED_CAMPAIGNS",
    UPDATE_CAMPAIGN: "UPDATE_CAMPAIGN",
    DELETE_CAMPAIGN: "DELETE_CAMPAIGN",
    GET_CAMPAIGN_ITEM: "GET_CAMPAIGN_ITEM"
}

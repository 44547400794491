import { statsConstants } from "../constants/stats";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case statsConstants.GET_STATS:
            //console.log(state)
            return state;
        case statsConstants.GET_STATS_SUCCESS:
            // console.log('reducer', action.data)
            return action.data;
        case statsConstants.GET_STATS_UPDATE_ITEM:
            let newState = [...state];
            newState.map(item => {
                if(item[0] === action.data[0]) {
                    item = action.data
                }
                return item;
            });
            return newState;
        case statsConstants.GET_STATS_FAILURE:
            return action.error;
        case statsConstants.GET_DATE_RANGE:
            return action.data;
        case statsConstants.SET_DATE_RANGE:
            return action.data;
        case statsConstants.UPDATE_NAVIGATION_TREE:
            return action.data;
        default:
            return state;
    }
}

import { boxRuleCampaigns } from "../constants/box_rule_campaigns";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case boxRuleCampaigns.GET_BOX_RULE_CAMPAIGN:
            // console.log(state)
            return state;
        case boxRuleCampaigns.UPDATE_BOX_RULE_CAMPAIGN:
            // console.log('box_rule_',action.data)
            return {...state, ...action.data}
        default:
            return state;
    }
}

import { useEffect, useState } from "V3";
import { Grid } from "@material-ui/core";
import { CreatorsHeader } from "../CreatorsHeader/CreatorsHeader.js";
import { V3Table } from "./V3Table.js";

export const TableCreator = (props) => {
    let { sectionName, layoutAssets } = props || {};
    const { pageState, setPageState, setIsLoading } = layoutAssets || {};
    const sectionState = pageState[sectionName];
    const { tableCreator, getTableData } = sectionState || {};
    const [isSectionChanged, setIsSectionChanged] = useState(false);
    const setSettings = (val, key) => setPageState(val, `${sectionName}.tableCreator.${key}`);
    layoutAssets = { ...layoutAssets, sectionName, sectionState, tableCreator, setSettings, isSectionChanged };

    /**
     *  Get Table onLoad
     */
    useEffect(() => getTableData(setIsLoading, layoutAssets), []);

    /**
     * checkIsSectionChanged
     */
    useEffect(() => setIsSectionChanged(!isSectionChanged), [tableCreator]);

    /**
     * Clear search if new data arrives.
     */
    useEffect(() => setSettings("", "internalSearchValue"), [tableCreator.data]);

    /**
     * Return
     */
    return (
        <CreatorsHeader layoutAssets={layoutAssets}>
            <Grid container xs={12} sx={{ width: "100%", marginLeft: 0, paddingRight: "16px", paddingTop: "8px" }}>
                <V3Table layoutAssets={layoutAssets} />
            </Grid>
        </CreatorsHeader>
    );
};

import { statsConstants } from "../constants/stats";

const initialState = {};

export default function statsLoading(state = initialState, action) {
    switch (action.type) {
        case statsConstants.LOADING_TRUE:
            return true;
        case statsConstants.LOADING_FALSE:
            return false;
        default:
            return state;
    }
}

/** @jsxImportSource @emotion/react */
import CircularProgress from "@material-ui/core/CircularProgress";

const cssRoot = {
    display: "flex",
    "& > * + *": {
        // marginLeft: theme.spacing(2),
    },
};

export default function CircularLoader() {
    return (
        <div css={cssRoot}>
            <CircularProgress />
        </div>
    );
}

import { useDispatch } from 'react-redux';
import { metadata as metadataActions } from 'store/actions/metadata';

export function useRefreshMetadata() {
    const dispatch = useDispatch();

    const refreshMetadata = (queryArgs) => {
        setTimeout(() => {
            dispatch(metadataActions.refreshMetaData(queryArgs));
        }, 1000);
    };

    return refreshMetadata;
}
//  background-color: ${(p) => (p.test2Color ? p.test2Color : 'red')};
//  ${(p) => css` background-color: ${p.test3Color}; `}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

// import { isDark } from "V3";
import { styled, css } from "V3/Components/styledMixins/styledMixins.js";

export const Container = styled.div`
   // padding-top:5px;
    width: 100%;
    ${(p) => {
        return (
            p?.noBorder &&
            css`
                & > div > div > fieldset {
                    border: none;
                }
            `
        );
    }}
`;

export const clientsConstants = {
    GET_CLIENT: "GET_CLIENT",
    GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
    GET_CLIENT_FAILURE: "GET_CLIENT_FAILURE",
    GET_CLIENT_UPDATE_ITEM: "GET_CLIENT_UPDATE_ITEM",
    CREATE_CLIENT: "CREATE_CLIENT",
    CREATED_CLIENTS: "CREATED_CLIENTS",
    UPDATE_CLIENT: "UPDATE_CLIENT",
    DELETE_CLIENT: "DELETE_CLIENT",
    GET_CLIENT_META: "GET_CLIENT_META",
    GET_CLIENT_META_SUCCESS: "GET_CLIENT_META_SUCCESS",
    GET_CLIENT_META_FAILURE: "GET_CLIENT_META_FAILURE",
    GET_CLIENT_ITEM: "GET_CLIENT_ITEM"
}

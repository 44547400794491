//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

// import { isDark } from "V3";
import { styled } from "V3/Components/styledMixins/styledMixins.js";

export const Container = styled.div`
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const SignInButton = styled.button`
    background: #321fdb;
    border-radius: 5px;
    box-shadow: 2px 5px 10px #999999;
    color: #fff;
    padding: 15px 30px;
`;

const fix = (text, fix = 2) => {
    const val = Number(Number(text).toFixed(fix));
    if (!val || isNaN(val)) return 0;
    else return val.toLocaleString();
};

const epc = (cost, clicks) => fix(cost / (clicks || 1));
const epl = (cost, leads) => fix(cost / (leads || 1));
const signupPerc = (leads, impressions) => fix(leads / (impressions || 1)) * 100;
const qftdPerc = (conversions, impressions) => fix((conversions / (impressions || 1)) * 100);

export const calc = {
    fix,
    epc,
    epl,
    signupPerc,
    qftdPerc,
};

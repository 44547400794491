/**
 * typeOfAdvanced
 * @description an advanced version of typeOf
 * @param {*} data
 * @returns {string} "string" || "number" || "boolean" || "function" || "array" || "object" || "undefined data"
 */

export const typeOfAdvanced = (data) => {
    let typeofData = typeof data;

    if (typeofData === "string") {
        return "string";
    } else if (typeofData === "number") {
        return "number";
    } else if (typeofData === "boolean") {
        return "boolean";
    } else if (typeofData === "function") {
        return "function";
    } else if (typeofData === "object") {
        if (Array.isArray(data)) {
            return "array";
        } else {
            return "object";
        }
    } else {
        return "undefined data";
    }
};

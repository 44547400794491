import { campaignsSplitConstants } from "../constants/campaigns_split";
import _ from 'lodash'

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case campaignsSplitConstants.GET_CAMPAIGN_SPLIT_SUCCESS:
            return splitCampaigns(action.data.data);
        default:
            return state;
    }
}

const splitCampaigns = (data) => {
    let splitVars = {}
    _.forEach(data, (item) => {
        if(!splitVars.hasOwnProperty(item.landerType)) {
            splitVars[item.landerType] = []
        }
        splitVars[item.landerType].push(item)
    })
    return splitVars
}

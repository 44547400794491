import { useState, isDark, TextField, useEffect, _, Fragment, useDebounce, useMemo } from "V3";
import {
    Table as MuiTable,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination,
} from "@material-ui/core";
import CircularLoader from "components/loader/CircularLoader";
import { makeStyles } from "@material-ui/styles";

/**
 * STYLES
 */
const useStyles = makeStyles({
    headerRow: {
        "& th": {
            backgroundColor: ({ isDark }) => (isDark ? "#0E0E16" : "#fff"),
            padding: "20px 20px",
            whiteSpace: "nowrap",
        },
    },
    longText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "300px",
        whiteSpace: "nowrap",
        display: "inline-block",
        paddingTop: 15,
        textTransform: "lowercase",
    },
    allRow: {
        "&:nth-child(2n)": {
            backgroundColor: ({ isDark }) => (isDark ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"),
        },
    },
    retroRow: {
        backgroundColor: "rgba(255,0,0,0.3)",
    },
    allCell: {
        fontSize: 12,
        maxWidth: "300px",
        overflow: "visible",
        whiteSpace: "normal",
        wordBreak: "break-all",
        padding: "5px 20px",
    },
    noDataText: {
        width: "100%",
        fontSize: 24,
        fontWeight: 300,
        padding: "5px 20px",
        marginTop: 50,
    },
});

export const Table = (props) => {
    const classes = useStyles({ isDark });
    const {
        internalSearch = false,
        cellSettings,
        data = [],
        setData,
        defaultOrder = "asc",
        defaultOrderBy = "key",
        pagination = true,
        loading = false,
    } = props || {};

    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [internalSearchValue, setInternalSearchValue] = useState({ search: "" });
    const [page, setPage] = useState(0);
    const rowsPerPage = 50;

    /**
     * Data - Search Acts
     */
    const filterFunc = (row) => {
        if (!internalSearch || internalSearchValue.search === "") return true;
        let anyMatch = false;
        _.forEach(cellSettings, (cell) => {
            if (cell.RenderComponent) return;
            let val;
            if (cell.render) val = row[cell.id + "RenderedValue"];
            else val = row[cell.cellData];
            if (((val && val + "") || "").toLowerCase().includes((internalSearchValue.search || "").toLowerCase())) {
                anyMatch = true;
                return false;
            }
        });
        return anyMatch;
    };
    // debounce internalSearchValue
    const [debouncedSearchValue] = useDebounce(internalSearchValue, 250);

    // apply filtering with debounce
    const filteredData = useMemo(() => {
        return data.filter((row) => filterFunc(row, debouncedSearchValue.search));
    }, [data, debouncedSearchValue]);

    useEffect(() => {
        if (!props.setFilteredData) return;
        if (filteredData.length === 0) props.setFilteredData([]);
        props.setFilteredData(filteredData);
    }, [debouncedSearchValue]);

    useEffect(() => {
        const filteredDataLength = filteredData.length;
        if (filteredDataLength !== data.length && page !== 0) {
            setPage(0);
        }
    }, [filteredData.length]);
    /**
     * onChangeHandler for internal Search
     */
    const onChange = (val, name) => {
        setInternalSearchValue({ ...internalSearchValue, [name]: val });
    };

    /**
     *
     */
    const renderAllCells = () => {
        if (!data || data.length < 1) return;
        let isANewData = true;
        let newData = _.cloneDeep(data);
        _.forEach(newData, (row, rowKey) => {
            _.forEach(cellSettings, (cell) => {
                if (!cell.render) return;
                if (newData[rowKey][cell.id + "RenderedValue"]) {
                    isANewData = false;
                    return false;
                }
                newData[rowKey][cell.id + "RenderedValue"] = cell.render({ item: row });
            });
        });
        if (isANewData && !_.isEqual(newData, data)) setData(newData);
    };
    useEffect(() => renderAllCells(), [data]);

    /**
     * Sort Functions
     */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    /**
     * Return
     */
    if (((!cellSettings || data.length < 1) && !loading) && !internalSearchValue.search)
        return <div className={classes.noDataText}>There isn't any data to show.</div>

    return (
        <Fragment>
            {!internalSearch ? null : (
                <div style={{ paddingTop: "5px" }}>
                    <TextField label="Search" name="search" disabled={loading} onChange={onChange} state={internalSearchValue} /> </div>
            )}
            {loading ? <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '100px',
                height: '100vh',
            }}>
                <CircularLoader />
            </div> : <> <TableContainer className={classes.container} style={{ marginTop: internalSearch ? 20 : 0 }}>
                <MuiTable aria-labelledby="tableTitle" size="normal" aria-label="enhanced table">
                    {/** HEADER SECTION*/}
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            {cellSettings.map((headCell, key) => (<TableCell
                                key={headCell.id}
                                align={headCell.align || "left"}
                                padding={key === 0 ? "normal" : "none"}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {headCell.id === "actions" ? headCell.label : <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={headCell.id !== "actions" ? createSortHandler(headCell.id) : null}
                                    sx={{ textTransform: "capitalize" }}
                                >
                                    {headCell.label}
                                </TableSortLabel>}
                            </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/** TABLE CONTENT */}
                    <TableBody>
                        {filteredData.sort((a, b) => {
                            const orderByCellData = cellSettings && cellSettings[orderBy - 1]?.cellData;
                            let valA = a[orderBy + "RenderedValue"] || a[orderByCellData] || a[orderBy] || -1;
                            let valB = b[orderBy + "RenderedValue"] || b[orderByCellData] || b[orderBy] || -1;

                            valA = String(valA);
                            valB = String(valB);

                            if (isNaN(Number(valA)) || isNaN(Number(valB))) {
                                return order === "asc" ? valB?.localeCompare(valA) : valA?.localeCompare(valB);
                            } else {
                                if (Number(valA) > Number(valB)) return order === "asc" ? 1 : -1;
                                else if (Number(valA) < Number(valB)) return order === "asc" ? -1 : 1;
                                return 0;
                            }
                        })
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((rowData, rowIndex) => {
                                const labelId = `enhanced-table-checkbox-${rowIndex}`;

                                return (
                                    <SingleRow
                                        isRetro={rowData.retro_date && rowData.retro_date !== null}
                                        rowIndex={rowIndex}
                                        labelId={labelId}
                                        classes={classes}
                                        {...props}
                                    >
                                        {cellSettings.map((singleCellSettings, cellIndex) => (
                                            <SingleCell
                                                classes={classes}
                                                rowData={rowData}
                                                rowIndex={rowIndex}
                                                singleCellSettings={singleCellSettings}
                                                cellIndex={cellIndex}
                                                labelId={labelId}
                                                {...props}
                                            />
                                        ))}
                                    </SingleRow>
                                );
                            })}
                    </TableBody>
                </MuiTable>
            </TableContainer>
            </>}
            {/*PAGINATION*/}
            {!pagination || filteredData.length <= rowsPerPage ? null : (
                <TablePagination
                    rowsPerPageOptions={[]}
                    showFirstButton
                    showLastButton
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                />
            )}
        </Fragment>
    );
};

export const SingleRow = (props) => {
    const { classes, rowIndex, children, isRetro } = props || {};

    return (
        <TableRow tabIndex={-1} key={rowIndex} className={isRetro ? classes.retroRow : classes.allRow}>
            {children}
        </TableRow>
    );
};

export const SingleCell = (props) => {
    const { classes, rowData, singleCellSettings } = props || {};

    return (
        <TableCell
            align={singleCellSettings.align || "left"}
            padding={singleCellSettings.padding || "none"}
            className={classes.allCell}
            {...(singleCellSettings.cellStyle && {
                style:
                    typeof singleCellSettings.cellStyle === "function"
                        ? singleCellSettings.cellStyle({ item: rowData })
                        : singleCellSettings.cellStyle,
            })}
        >
            {singleCellSettings.cellData
                ? rowData[singleCellSettings.cellData]
                : singleCellSettings.render
                    ? rowData[singleCellSettings.id + "RenderedValue"]
                    : singleCellSettings.RenderComponent
                        ? singleCellSettings.RenderComponent({ item: rowData, ...props })
                        : "-"}
        </TableCell>
    );
};
export const statsConstants = {
    GET_STATS: "GET_STATS",
    GET_STATS_SUCCESS: "GET_STATS_SUCCESS",
    GET_STATS_FAILURE: "GET_STATS_FAILURE",
    GET_STATS_UPDATE_ITEM: "GET_STATS_UPDATE_ITEM",
    GET_DATE_RANGE: "GET_DATE_RANGE",
    SET_DATE_RANGE: "SET_DATE_RANGE",
    UPDATE_NAVIGATION_TREE: "UPDATE_NAVIGATION_TREE",
    GET_STATS_TOTALS_SUCCESS: "GET_STATS_TOTALS_SUCCESS",
    LOADING_TRUE: "LOADING_TRUE",
    LOADING_FALSE: "LOADING_FALSE"
}

import { integrationsConstants } from "../constants/integrations";
import _ from 'lodash';

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case integrationsConstants.GET_INTEGRATION:
            //console.log(state)
            return state;
        case integrationsConstants.GET_INTEGRATION_SUCCESS:
            // console.log(parseData(action.data.data))
            return parseData(action.data.data);
        case integrationsConstants.GET_INTEGRATION_UPDATE_ITEM:
            let newState = [...state];
            newState.map(item => {
                if(item[0] === action.data[0]) {
                    item = action.data
                }
                return item;
            });
            return newState;
        case integrationsConstants.GET_INTEGRATION_FAILURE:
            return action.error;
        default:
            return state;
    }
}

function parseData(data) {
    _.forEach((data), (item, index) => {
        data[index].settingsJson = JSON.stringify(item.settings)
    })
    return data;
}

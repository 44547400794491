import { React, useState, OldRow, _, V3AutoComplete } from "V3";
import { ErrorTextArea } from "V3";
import { SelectBoxMultipleAllocationList, useEffect } from "V3";
import { useSelector } from "react-redux";
import "./FinSelectBoxCampaingAllocation.scss";


export const FinSelectBoxCampaingAllocation = (props) => {
    const { name, ctaLabels, isNativeBox, isNativeBanner, isRulesForm, isReorderingLocked } = props;
    const { errors, setStore } = props;
    const { metadata } = useSelector((state) => state);
    const [localVal, setLocalVal] = useState();
    const [onFocus, setOnFocus] = useState(false);
    const [customOptions, setCustomOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Add local assets into props
     */
    useEffect(() => {
        if (isNativeBox === undefined) return; /*|| isNativeBox === false*/
        if (isRulesForm) return; // fix not to became empty on rules form pod modal
        setStore((p) => ({ ...p, [name]: null }));
    }, [isNativeBox]);

    useEffect(() => {
        setIsLoading(true);
        const { data } = metadata || {};

        if (name === "offerLanderCampaigns") {
            setCustomOptions(data?.combinedCampaigns || [])
        } else {
            const preLanderCampaigns = data?.preLanderCampaigns || [];
            let options = preLanderCampaigns.filter(({ type }) =>
                isNativeBox ? type === "native_static" : type === "regular"
            );
            setCustomOptions(options);
        }
        setIsLoading(false);
    }, [isNativeBox, metadata, name]);

    const additionalProps = {
        ...props,
        eplToggle: props.eplToggle !== undefined ? props.eplToggle : props.store.eplWeights || false,
        localVal,
        //setLocalVal,
        ctaLabels,
        onFocus,
        setOnFocus,
        metadata,
        isNativeBox: isNativeBox,
        isNativeBanner: isNativeBanner,
        fieldErrors: errors,
        isReorderingLocked: isReorderingLocked,
        options: customOptions,
        allocation: props.allocation || true,
        maxOptionLength: 50,
        infoText: "Results are limited at 50. Please use filter option to find wanted result.",
    };

    /**
     * Return
     */

    useEffect(() => {
        if (!localVal?.value?.id) return;
        let newOptions = _.cloneDeep(props.store[props.name] || []);
        newOptions.unshift(localVal.value);
        props.setStore((prev) => ({
            ...prev,
            [props.name]: newOptions,
        }));
        const button = document.querySelector('button[aria-label="Clear"]');
        if (button) {
            button.click();
        }

    }, [localVal?.value?.id])


    if (isLoading) return <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>Loading...</div>
    return (
        <>
            <OldRow {...additionalProps}>

                <V3AutoComplete
                    label={name}
                    name={name}
                    options={customOptions}
                    getOptionLabel={(o) => o.name}
                    isOptionEqualToValue={(o, v) => o.id === v.id}
                    onChange={setLocalVal}
                    value={localVal}
                />
                <SelectBoxMultipleAllocationList {...additionalProps} />
                <ErrorTextArea onFocus={onFocus} {...additionalProps} />
            </OldRow>
        </>
    );
};

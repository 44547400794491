import { ruleCampaigns } from "../constants/rule_campaigns";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case ruleCampaigns.GET_RULE_CAMPAIGN:
            // console.log('get',{...state, ...action.data})
            return state;
        case ruleCampaigns.UPDATE_RULE_CAMPAIGN:
            // console.log('update', {...state, ...action.data})
            return {...state, ...action.data}
        default:
            return state;
    }
}

import React, { Fragment } from "react";
import { CCol, CFormGroup, CLabel } from "@coreui/react";

export const OldRow = ({
    name,
    label,
    children,
    addStyleForWrapper = { alignItems: "center" },
    infoText,
    disableOldRow,
    ...props
}) => {
    /**
     * Return
     */
    if (!label || label === "" || disableOldRow) {
        return <Fragment>{children}</Fragment>;
    } else {
        return (
            <CFormGroup
                row
                style={{
                    ...addStyleForWrapper,
                    ...(props.onFocus && { backgroundColor: "rgba(255,255,255,0.05)" }),
                }}
            >
                <CCol md="3">
                    <CLabel htmlFor={name}>
                        <span
                            style={{
                                color:
                                    props.fieldErrors && props.fieldErrors[name] && props.fieldErrors[name].length > 0
                                        ? "#e55353"
                                        : "",
                            }}
                        >
                            {" "}
                            {label}
                        </span>
                    </CLabel>
                </CCol>
                <CCol xs="12" md="9">
                    {children}
                </CCol>
                <div
                    style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#333",
                    }}
                ></div>
            </CFormGroup>
        );
    }
};

export function validateStringOnlyLettersDigits(str) {
    if (!str) {
        return false;
    }
    const regEx = /^\w+$/;
    if (str.match(regEx)) {
        return true;
    }
    return false;
}

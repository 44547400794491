import { metadataConstants } from "../constants/metadata";
import headers from "../../config/headers";
import axios from "axios";
import { idLabelNameConvertor as con1 } from "./idLabelNameConvertor.js";

export const metadata = {
    getMetaData,
    refreshMetaData,
};

function getMetaData(queryArgs = null) {
    return (dispatch, getState) => {
        let getUrl = process.env.REACT_APP_API_URL + "metadata";
        axios.get(getUrl, headers()).then(
            (result) => {
                dispatch(success(metadataGlobalCorrections(result?.data)));
            },
            (error) => {
                console.log(error);
            },
        );
    };

    function success(data) {
        return {
            type: metadataConstants.GET_METADATA_SUCCESS,
            data,
        };
    }
}

function refreshMetaData(queryArgs = null) {
    return (dispatch, getState) => {
        let baseUrl = process.env.REACT_APP_API_URL + "metadata";
        let getUrl = queryArgs ? `${baseUrl}/${queryArgs}/refresh` : `${baseUrl}/refresh`;

        axios.get(getUrl, headers()).then(
            (result) => {
                if (queryArgs) {
                    dispatch(updateMetadataProperty(queryArgs, metadataGlobalCorrections(result.data)));
                } else {
                    dispatch(success(metadataGlobalCorrections(result.data)));
                }
                console.log("state metadata refreshed");
            },
            (error) => {
                console.log(error);
            },
        );
    };

    function success(data) {
        return {
            type: metadataConstants.GET_METADATA_SUCCESS,
            data,
        };
    }

    function updateMetadataProperty(key, data) {
        return {
            type: metadataConstants.UPDATE_METADATA_PROPERTY,
            key,
            data,
        };
    }
}

export const metadataGlobalCorrections = (res) => {
    if (res?.data?.commonOfferLanderCampaigns) {
        res.data.commonOfferLanderCampaigns = con1(res.data.commonOfferLanderCampaigns);
    }
    if (res?.data?.offerLanderCampaigns) {
        res.data.offerLanderCampaigns = con1(res.data.offerLanderCampaigns);
    }
    if (res?.data?.preLanderCampaigns) {
        res.data.preLanderCampaigns = con1(res.data.preLanderCampaigns);
    }
    if (res?.data?.externalLanderCampaigns) {
        res.data.externalLanderCampaigns = con1(res.data.externalLanderCampaigns);
    }
    if (res?.data?.externalLanderCampaignData) {
        res.data.externalLanderCampaignData = con1(res.data.externalLanderCampaignData);
    }
    if (res?.data?.publishers) {
        res.data.publishers = con1(res.data.publishers);
    }

    return res;
};

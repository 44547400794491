import { useState, useEffect, Fragment, _ } from "V3";
import { Grid } from "@material-ui/core";
import { CreatorsHeader } from "../CreatorsHeader/CreatorsHeader.js";
import { FormCreatorActions } from "./FormCreatorActions.js";
import {
    validateAndHandleIsolated,
    checkIsSectionChangedAndValid,
    validationCheckForAll,
} from "../V3LayoutCreatorFunctions.js";

export const FormCreator = (props) => {
    let { sectionName, layoutAssets, onChange } = props || {};
    const { pageState, componentSelector, setPageState, dynamicVariables } = layoutAssets || {};
    const sectionState = pageState[sectionName];
    const [isSectionChanged, setIsSectionChanged] = useState(false);
    const [isSectionValid, setIsSectionValid] = useState(false);
    
    /**
     *
     */
    useEffect(() => {
        if (sectionState?.formCreatorActionsDefaultChange) {
            const formFields = sectionState.formCreatorFields;
            const formDataForSubmit = {};
            _.forOwn(formFields, (field, key) => {
                formDataForSubmit[key] = field.currentValue;
            });
            layoutAssets = { ...layoutAssets, formDataForSubmit };
            sectionState.formCreatorActionsDefaultChange(layoutAssets);
        }
    }, [sectionState?.formCreatorFields]);

    /**
     * validationCheckForAll onLoad
     */
    useEffect(() => setTimeout(() => validationCheckForAll({ sectionName, layoutAssets }), 500), []);
    const [isChecked, setIsChecked] = useState();
    useEffect(() => {
        if (isChecked) return;
        setIsChecked(true);
        validationCheckForAll({ sectionName, layoutAssets });
        setTimeout(() => {
            setIsChecked(false);
        }, 500);
    }, [dynamicVariables]);

    /**
     * checkIsSectionChangedAndValid
     */
    useEffect(
        () => checkIsSectionChangedAndValid({ sectionState, setIsSectionChanged, setIsSectionValid }),
        [sectionState?.formCreatorFields],
    );

    /**
     * onChangeHandler
     */
    const onChangeHandler = (newValue, fieldName, fieldState) => {
        validateAndHandleIsolated({ newValue, fieldName, fieldState, setPageState, sectionName, pageState, onChange });
    };

    /**
     * layoutAssets
     */
    layoutAssets = { ...layoutAssets, sectionName, sectionState, isSectionChanged, isSectionValid };

    /**
     * Return
     */
    return (
        /**
         *
         * CREATIRSHEADER => HEADER & HEADER ACTIONS
         *
         */
        <CreatorsHeader layoutAssets={layoutAssets}>
            <Grid container xs={12} sx={{ width: "100%", marginLeft: 0, paddingTop: "8px" }} spacing={2}>
                {/**
                 *
                 * FORM CREATOR => FORM FIELDS
                 *
                 */}
                {Object.entries(sectionState?.formCreatorFields).map(([key, obj]) => {
                    // console.log(key, obj);

                    if (obj.hide) {
                        const typeOf = typeof obj.hide;
                        const hide = typeOf === "function" ? obj.hide(dynamicVariables) : obj.hide;
                        if (hide) return null;
                    }
                    const NewComponent = obj.component || componentSelector(obj.componentName);
                    return (
                        <Fragment>
                            {obj.size !== "single" ? null : <Grid item xs={0} md={1} />}
                            <Grid
                                item
                                xs={12}
                                md={obj.size ? (obj.size === "single" ? 6 : obj.size) : true}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: 0,
                                    paddingTop: 0,
                                    paddingRight: "16px",
                                }}
                            >
                                <NewComponent
                                    layoutAssets={{ ...layoutAssets, sectionName, sectionState, fieldName: key }}
                                    name={key}
                                    value={obj.currentValue}
                                    error={!isSectionChanged ? false : obj.isValid === false ? true : false}
                                    onChange={(newValue, fieldName) => onChangeHandler(newValue, fieldName, obj)}
                                    {...obj.componentProps}
                                />
                            </Grid>
                            {obj.size !== "single" ? null : <Grid item xs={0} md={5} />}
                        </Fragment>
                    );
                })}
                {/**
                 *
                 * FORM CREATOR ACTIONS
                 *
                 */}
                {sectionState.formCreatorActionsDefaultSubmit.size !== "single" ? null : <Grid item xs={0} md={1} />}
                <Grid
                    item
                    xs={12}
                    md
                    rowSpacing={2}
                    columnSpacing={2}
                    sx={{
                        // marginTop: "15px",
                        display: "flex",
                    }}
                >
                    <FormCreatorActions layoutAssets={layoutAssets} />
                </Grid>
            </Grid>
        </CreatorsHeader>
    );
};

import { useState, useEffect, useRef, PxaLottiePlayer } from "V3";
import "./LoadingAnimation.scss";
import loadingAnimationJSON from "./loadingAnimationJSON.json";

export const LoadingAnimation = (props) => {
    const { isLoading = false } = props || {};
    const [innerTrigger, setInnerTrigger] = useState(isLoading);
    const [innerTimer, setInnerTimer] = useState(true);
    const timeoutRef = useRef();

    /**
     * Open loading scene immediately, if isLoading is true
     */
    useEffect(() => {
        if (isLoading) {
            clearTimeout(timeoutRef.current);
            setInnerTrigger(true);
            setInnerTimer(true);
            timeoutRef.current = setTimeout(() => setInnerTimer(false), 1000);
        } else {
            if (!innerTimer) {
                setInnerTrigger(false);
            }
        }
    }, [isLoading]);

    /**
     * Check InnerTimer's behaivours
     */
    useEffect(() => {
        if (!isLoading) setInnerTrigger(false);
    }, [innerTimer]);

    return (
        <div className={"LoadingAnimation_container" + (!innerTrigger ? " off" : "")}>
            <div className="innerLoading">
                <PxaLottiePlayer
                    name="loadingAnimationJSON"
                    size="100%"
                    src={loadingAnimationJSON}
                    autoplay={true}
                    loop={true}
                    speed="1"
                />
                <span>LOADING</span>
            </div>
        </div>
    );
};

// import { _ } from "V3";

const defaultUpdateParent = (val, props) => {
    props.setUpdatedData({ ...props.updatedData, [props.name]: val });
};
const debouncedLocalChange = (val, props) => {
    props.updateParent(val, props);
    if (props.FormCreatorErrorController) props.FormCreatorErrorController(val, props);
};
const isError = (props) => {
    if (props.fieldErrors && props.fieldErrors[props.name] && props.fieldErrors[props.name].length > 0) return true;
    return false;
};

/**
 * Add new functions into FF for a easier import
 */
export const FormFunctions = {
    defaultUpdateParent: defaultUpdateParent,
    debouncedLocalChange: debouncedLocalChange,
    isError: isError,
};

import { React } from "V3";
import "./ErrorTextArea.scss";

export const ErrorTextArea = (props) => {

    return (
        <div
            className={
                "ErrorTextArea" + (/*props.onFocus &&*/ props.fieldErrors && props.fieldErrors[props.name] ? " on" : "")
            }
        >
            {props.fieldErrors && props.fieldErrors[props.name]
                ? props.fieldErrors[props.name].map((item, key) => {
                      return <h6 key={key}>• {item}</h6>;
                  })
                : ""}
        </div>
    );
};

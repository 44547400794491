import { userdataConstants } from "../constants/user_data";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case userdataConstants.GET_USERDATA:
            //console.log(state)
            return state;
        case userdataConstants.GET_USERDATA_SUCCESS:
            return action.data.data;
        case userdataConstants.GET_USERDATA_UPDATE_ITEM:
            let newState = [...state];
            newState.map(item => {
                if(item[0] === action.data[0]) {
                    item = action.data
                }
                return item;
            });
            return newState;
        case userdataConstants.GET_USERDATA_FAILURE:
            return action.error;
        default:
            return state;
    }
}

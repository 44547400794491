import { useState, isDark, V3TextField, useEffect, _, Fragment } from "V3";
import {
    Table as MuiTable,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

/**
 * STYLES
 */
const useStyles = makeStyles({
    container: {
        overflow: "unset",
    },
    headerRow: {
        "& th": {
            // position: "sticky",
            // top: 104,
            zIndex: 2,
            backgroundColor: ({ isDark }) => (isDark ? "#0E0E16" : "#fff"),
            padding: "20px 20px",
            whiteSpace: "nowrap",
        },
    },
    longText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "300px",
        whiteSpace: "nowrap",
        display: "inline-block",
        paddingTop: 15,
        textTransform: "lowercase",
    },
    allRow: {
        // userSelect: "none",
        "&:nth-child(2n)": {
            backgroundColor: ({ isDark }) => (isDark ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"),
        },
    },
    allCell: {
        fontSize: 12,
        maxWidth: "300px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "5px 20px",
    },
});

export const V3Table = (props) => {
    const classes = useStyles({ isDark });
    const [filteredData, setFilteredData] = useState([]);
    const { layoutAssets } = props || {};
    const { tableCreator, setSettings } = layoutAssets || {};
    const {
        cellSettings,
        data,
        order,
        orderBy,
        internalSearch,
        internalSearchValue,
        //page = 0,
        pagination,
        rowsPerPage,
    } = tableCreator || {};
    const [page, setPage] = useState(0);

    /**
     * Sort Functions
     */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setSettings(isAsc ? "desc" : "asc", "order");
        setSettings(property, "orderBy");
    };
    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    /**
     * Define Filtered Data
     */
    useEffect(() => {
        if (!data || data.length < 1) return;
        const filterFunc = (row) => {
            if (!internalSearch || internalSearchValue === "") return true;
            let anyMatch = false;
            _.forEach(cellSettings, (cell) => {
                if (cell.RenderComponent) return;
                let val;
                if (cell.screenValue) val = row[cell.id + "RenderedValue"] + "";
                else val = row[cell.cellData] + "";
                if ((val || "").toLowerCase().includes((internalSearchValue || "").toLowerCase())) {
                    anyMatch = true;
                    return false;
                }
            });
            return anyMatch;
        };
        let result = data.filter(filterFunc);
        setFilteredData(result);
    }, [data, internalSearchValue]);

    /**
     * Return
     */
    //if (!filteredData || filteredData.length < 1) return null;
    return (
        <Fragment>
            {!internalSearch ? null : (
                <V3TextField
                    label="Search"
                    name="internalSearchValue"
                    onChange={setSettings}
                    value={internalSearchValue}
                />
            )}
            <TableContainer className={classes.container} style={{ marginTop: internalSearch ? 20 : 0 }}>
                <MuiTable aria-labelledby="tableTitle" size="normal" aria-label="enhanced table">
                    {/** HEADER SECTION*/}
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            {cellSettings.map((headCell, key) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.align || "left"}
                                    padding={key === 0 ? "normal" : "none"}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(headCell.id)}
                                        sx={{ textTransform: "capitalize" }}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/** TABLE CONTENT */}
                    <TableBody>
                        {filteredData
                            ?.sort((a, b) => {
                                const valA = a[orderBy + "RenderedValue"] || a[orderBy] || -1;
                                const valB = b[orderBy + "RenderedValue"] || b[orderBy] || -1;
                                if (valA < valB) return order === "asc" ? -1 : 1;
                                else if (valA > valB) return order === "asc" ? 1 : -1;
                                return 0;
                            })
                            .slice(
                                page * rowsPerPage,
                                !pagination ? filteredData?.length : page * rowsPerPage + rowsPerPage,
                            )
                            .map((rowData, rowIndex) => {
                                const labelId = `enhanced-table-checkbox-${rowIndex}`;
                                return (
                                    <SingleRow rowIndex={rowIndex} labelId={labelId} classes={classes} {...props}>
                                        {cellSettings.map((singleCellSettings, cellIndex) => (
                                            <SingleCell
                                                classes={classes}
                                                rowData={rowData}
                                                rowIndex={rowIndex}
                                                singleCellSettings={singleCellSettings}
                                                cellIndex={cellIndex}
                                                labelId={labelId}
                                                layoutAssets={layoutAssets}
                                                {...props}
                                            />
                                        ))}
                                    </SingleRow>
                                );
                            })}
                    </TableBody>
                </MuiTable>
            </TableContainer>
            {/** PAGINATION */}
            {!pagination || filteredData?.length <= rowsPerPage ? null : (
                <TablePagination
                    rowsPerPageOptions={[]}
                    showFirstButton
                    showLastButton
                    component="div"
                    count={filteredData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                />
            )}
        </Fragment>
    );
};

export const SingleRow = (props) => {
    const { classes, rowIndex, children } = props || {};

    return (
        <TableRow tabIndex={-1} key={rowIndex} className={classes.allRow}>
            {children}
        </TableRow>
    );
};

export const SingleCell = (props) => {
    const { classes, rowData, singleCellSettings } = props || {};
    return (
        <TableCell
            align={singleCellSettings.align || "left"}
            padding={singleCellSettings.padding || "none"}
            className={classes.allCell}
            {...(singleCellSettings.cellStyle && {
                style: singleCellSettings.cellStyle,
            })}
        >
            {singleCellSettings.cellData
                ? rowData[singleCellSettings.cellData]
                : singleCellSettings.screenComponent
                ? singleCellSettings.screenComponent({
                      item: rowData[singleCellSettings.id],
                      rowData: rowData,
                      ...props,
                  })
                : singleCellSettings.screenValue
                ? rowData[singleCellSettings.id + "RenderedValue"]
                : "-"}
        </TableCell>
    );
};

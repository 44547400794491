import { boxCampaigns } from "../constants/box_campaigns";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case boxCampaigns.GET_BOX_CAMPAIGN:
            // console.log(state)
            return state;
        case boxCampaigns.UPDATE_BOX_CAMPAIGN:
            // console.log('box_',action)1
            return {...state, ...action.data}
        default:
            return state;
    }
}

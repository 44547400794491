export const ClientEvents = (globalBreakpoints) => {
    const WINW = window.innerWidth || document.documentElement.clientWidth || 0;
    const WINH = window.innerHeight || document.documentElement.clientHeight || 0;
    const isSafeSize = WINW > 991 && WINH > 500 ? true : false;
    const isMobile = WINW < 767 ? true : false;
    const { detect } = require("detect-browser");
    const detectBrowser = detect();
    let sortedBreakpoints = Object.entries(globalBreakpoints).sort((a, b) => a[1] - b[1]);
    let i = 0;
    while (i < sortedBreakpoints.length && Number(sortedBreakpoints[i][1]) < WINW) {
        i++;
    }

    const CLIENTEVENTS = {
        winW: WINW,
        winH: WINH,
        currentBreakpoint: sortedBreakpoints[i][0],
        isSafeSize: isSafeSize,
        isMobile: isMobile,
        browser: detectBrowser.name || "undefined",
        browserVersion: detectBrowser.version || "undefined",
        os: detectBrowser.os || "undefined",
        language: navigator.language || navigator.userLanguage || "undefined",
    };

    return CLIENTEVENTS;
};

/**
 * List of Language Codes
 * Here is the full list:
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 *
 * en   English
 * tr   Turkish
 * pt   Portegues
 * es   Spanish
 * th   Thai
 * de   German
 * fr   French
 * zh   Chinese
 * it   Italian
 */

/*	USAGE
	arr=[{id:1,name:"Turkey"},...(length: 3)]
	sourceArr=[{id:1,country:"Turkey",code:"TR",...},...(length 300)]
	findInSource(arr,sourceArr,"id");
	result -> newArr = [{id:1,country:"Turkey",code:"TR",...},...(length 3)]
*/
import _ from "lodash";
import { typeOfAdvanced } from "V3";

export const findInSource = (arr, sourceArr, matchKey) => {
    if (!arr || !sourceArr || !matchKey) {
        console.log("missing information at findInSource");
        return;
    }
    // console.log(arr, sourceArr, matchKey);
    const typeOfArr = typeOfAdvanced(arr);
    const newArr = [];
    if (typeOfArr === "array") {
        _.forEach(arr, (it) => {
            const incomingKeyValue = it[matchKey];
            const indexInSource = _.findIndex(sourceArr, (o) => o[matchKey] === incomingKeyValue);
            newArr.push(sourceArr[indexInSource]);
        });
    } else if (typeOfArr === "string" || typeOfArr === "number") {
        const indexInSource = _.findIndex(sourceArr, (o) => o[matchKey] === arr);
        newArr.push(sourceArr[indexInSource]);
    } else {
        console.log("unknown typeOfAdvanded in findInSource");
    }
    return newArr;
};

import { useState } from "V3";
import MuiAutoComplete from "@material-ui/core/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const AutoComplete = ({
    fullWidth = true,
    name = "UnnamedField",
    defaultValue = "null",
    label = "Unnamed Field",
    options = [],
    value = false,
    onChange = false,
    inputValue = false,
    onInputChange = false,
    // settings
    clearOnEscape = false,
    disableClearable = false,
    required = false,
    disabled = false,
    filterSelectedOptions = true,
    multiple = false,
    variant = "outlined",
    disablePortal = false,
    blurOnSelect = true,
    error = false,
    helperText = null,
    autoFocus = false,
    defaultHelperText = null,
    size = "small",
    disableCloseOnSelect = false,
    renderOption = null,
    InputProps,
    state,
    style,
    getOptionLabel = (option) => option,
    isOptionEqualToValue = (option, value) => option === value,
    ...props
}) => {
    const incomingValue = state && state[name] !== undefined ? state[name] : "";
    const [inputValueInner, setInputValueInner] = useState(incomingValue);
    /**
     * Return
     */
    return (
        <MuiAutoComplete
            id={name}
            name={name}
            options={options}
            getOptionLabel={getOptionLabel}
            value={incomingValue}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(event, newValue) => {
                if (onChange) onChange(newValue, name);
            }}
            inputValue={inputValue ? inputValue : inputValueInner}
            onInputChange={
                onInputChange
                    ? onInputChange
                    : (event, newInputValue) => {
                          setInputValueInner(newInputValue);
                      }
            }
            // settings
            fullWidth={fullWidth}
            required={required}
            clearOnEscape={clearOnEscape}
            variant={variant}
            filterSelectedOptions={filterSelectedOptions}
            blurOnSelect={blurOnSelect}
            error={error}
            helperText={error ? helperText : defaultHelperText}
            InputProps={InputProps}
            autoFocus={autoFocus}
            disabled={disabled}
            disableClearable={disableClearable}
            multiple={multiple}
            disablePortal={disablePortal}
            size={size}
            disableCloseOnSelect={disableCloseOnSelect}
            renderOption={renderOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    variant="outlined"
                    // placeholder="Please Type & Select"
                />
            )}
        />
    );
};

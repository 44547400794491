import { TextSeperator, Fragment } from "V3";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    iconButton: {
        marginTop: "-10px",
        // marginLeft: "10px",
        marginRight: "30px",
        padding: 0,
        "&:focus": {
            outline: 0,
            border: 0,
        },
    },
});

export const CreatorsHeaderTitleSection = (props) => {
    const classes = useStyles();
    const { settings, setIsExpanded, isExpanded } = props || {};
    const { label, isExpandable, expandIconExpanded, expandIconCollapsed } = settings || {};

    const onClick = () => setIsExpanded();
    const IconComponent = isExpanded
        ? expandIconExpanded || RemoveCircleOutlineIcon
        : expandIconCollapsed || ControlPointIcon;
    /**
     * Return
     */
    return (
        <Fragment>
            {!isExpandable ? null : (
                <IconButton onClick={onClick} className={classes.iconButton}>
                    <IconComponent />
                </IconButton>
            )}
            <TextSeperator t={label} onClick={isExpandable ? onClick : false} />
        </Fragment>
    );
};

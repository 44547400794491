//  background-color: ${(p) => (p.test2Color ? p.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    border-radius: 5px;
    border: 1px solid #606060;
    position: relative;
`;

export const OrderArea = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 20px 0;
    &:hover,
    &:focus {
        list-style-type: none;
    }
    & > li:nth-child(1) {
        border: 3px solid green;
        position: relative;
        &::after {
            position: absolute;
            content: "Highlighted Image";
            bottom: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 30px;
            background-color: green;
            opacity: 1;
        }
    }
`;
export const UploadZone = styled.label`
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.isHover ? "green" : props.isDark ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.05)"};
    margin-bottom: 20px;
    flex-direction: column;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    opacity: ${(props) => (props.isHover ? 1 : 0.5)};
    &:hover {
        background-color: green;
        opacity: 1;
    }
    & > div:nth-child(1) {
        opacity: 0.2;
    }
`;
export const ImageItem = styled.li`
    width: ${(props) => (props.square ? "300px" : "150px")};
    border: ${(props) => (props.isDark ? "1px solid rgba(255,255,255,0.7)" : "1px solid rgba(0,0,0,0.3)")};
    margin-right: 20px;
    user-select: none;
    list-style-type: none;
    position: relative;
    cursor: grab;
`;
export const Image = styled.img`
    width: 100%;
    min-height: 7.5rem;
`;
export const UploadZoneHiddenInput = styled.input`
    display: none;
`;
export const Label = styled.div`
    width: 100%;
    padding: 10px 10px 0 10px;
    font-weight: 400;
    font-size: 15px;
`;
export const LabelInfo = styled.div`
    width: 100%;
    padding: 0px 10px 10px 10px;
    font-weight: 400;
    font-size: 13px;
`;
export const CloseIconDiv = styled.div`
    width: 30px;
    height: 30px;
    background-color: darkred;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    opacity: 0.9;
    position: absolute;
    top: 0;
    right: 0;
    &:hover {
        opacity: 1;
        background-color: red;
    }
`;

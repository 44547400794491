import { combineReducers } from "redux";

import defaults from "./defaults";
import campaigns from "./campaigns";
import campaigns_split from "./campaigns_split";
import integrations from "./integrations";
import clients from "./clients";
import offers from "./offers";
import domains from "./domains";
import metadata from "./metadata";
import stats from "./stats";
import stats_totals from "./stats_totals";
import revenues, { revenuesMetaReducer } from "./revenues";
import trafficsources from "./trafficsources";
import campaignboxes from "./campaignboxes";
import rules from "./rules";
import rule_conditions from "./rule_conditions";
import rule_campaigns from "./rule_campaigns";
import box_rule_campaigns from "./box_rule_campaigns";
import box_campaigns from "./box_campaigns";
import redirects from "./redirects";
import prelanders from "./prelanders";
import user_data from "./user_data";
import prelander_domains from "./prelander_domains";
import affiliate_networks from "./affiliate_networks";
import userProfile from "./userProfile";
import statsLoading from "./statsLoading";

export default combineReducers({
    defaults,
    campaigns,
    campaigns_split,
    integrations,
    clients,
    offers,
    domains,
    metadata,
    stats,
    stats_totals,
    revenues,
    revenuesMeta: revenuesMetaReducer,
    trafficsources,
    campaignboxes,
    rules,
    rule_conditions,
    rule_campaigns,
    box_rule_campaigns,
    box_campaigns,
    redirects,
    prelanders,
    prelander_domains,
    user_data,
    affiliate_networks,
    userProfile,
    statsLoading,
});

import { campaignboxesConstants } from "../constants/campaignboxes";

const initialState = [];

export default function location(state = initialState, action) {
    switch (action.type) {
        case campaignboxesConstants.GET_CAMPAIGNBOX:
            //console.log(state)
            return state;
        case campaignboxesConstants.GET_CAMPAIGNBOX_SUCCESS:
            return action.data.data;
        case campaignboxesConstants.GET_CAMPAIGNBOX_UPDATE_ITEM:
            let newState = [...state];
            newState.map(item => {
                if(item[0] === action.data[0]) {
                    item = action.data
                }
                return item;
            });
            return newState;
        case campaignboxesConstants.GET_CAMPAIGNBOX_FAILURE:
            return action.error;
        default:
            return state;
    }
}

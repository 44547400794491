export const indexOf_ObjsInArr = (bigAr = [], newOp) => {
    let indexOf = -1;
    for (let i in bigAr) {
        const item = bigAr[i];
        if (item.name === newOp.name) {
            indexOf = i;
            break;
        }
    }
    return indexOf;
};

export const indexOf_ObjsInArrByIndex = (bigAr = [], newOp) => {
    let indexOf = -1;
    for (let i in bigAr) {
        const item = bigAr[i];
        if (item.value === newOp.value) {
            indexOf = i;
            break;
        }
    }
    return indexOf;
};